import { useState } from "react";
import type { FavoriteButtonProps } from ".";

function getNotFavoriteProps(): FavoriteButtonProps {
  return {
    isFavorite: false,
    onClick: () => {
      console.log("Favorite button clicked");
    },
  };
}

function getFavoriteProps(): FavoriteButtonProps {
  return {
    isFavorite: true,
    onClick: () => {
      console.log("Favorite button clicked");
    },
  };
}

function useDemoProps(): FavoriteButtonProps {
  const [isFavorite, setIsFavorite] = useState(false);
  return {
    isFavorite,
    onClick: () => {
      setIsFavorite(!isFavorite);
    },
  };
}

export const favoriteButtonFixtures = {
  getNotFavoriteProps,
  getFavoriteProps,
  useDemoProps,
};
